import { graphql } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect } from 'react';
import Divider from '@atoms/Divider/Divider';
import Section from '@atoms/Section/Section';
import Text from '@atoms/Text/Text';
import Title from '@atoms/Title/Title';
import Footer from '@components/Footer/Footer';
import Header from '@components/Header/Header';
import HeaderNav from '@components/HeaderNavV2/HeaderNav';
import Layout from '@components/layout';
import Seo from '@components/seo';
import { withLocale, withTracking } from '@hocs';
import { useTracking } from '@hooks';
export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

const ImprintPage = () => {
    const { t } = useTranslation();
    const { trackPageView } = useTracking();
    useEffect(() => {
        trackPageView();
    }, []);

    return (
        <Layout>
            <Seo title={`${t('legal.information.title')} | Aepsy`} />
            <div className="global_theme-green">
                <HeaderNav theme="white" />
                <Section theme="dark">
                    <Header
                        theme="dark"
                        title={t('legal.information.title')}
                        align="center"
                        spacing="s"
                        size="thin"
                    />
                </Section>

                <Section theme="light" spacing="m" container="large">
                    <Section spacing="xs">
                        <Title size="l">
                            {t('legal.information.contact.address')}
                        </Title>
                        <Divider spacing="s" invisible />

                        <Text>
                            Aepsy AG <br />
                            <a
                                href="https://www.google.com/maps/place/Seilergraben+53,+8001+Zürich/@47.3754943,8.5429398,17z/data=!3m1!4b1!4m5!3m4!1s0x479aa0a86e4f397f:0x3886d9c941798d6a!8m2!3d47.3754943!4d8.5451285"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Seilergraben 53
                            </a>{' '}
                            <br />
                            8001 Zürich <br />
                            Schweiz <br />
                            <a href="mailto:hey@aepsy.com">hey@aepsy.com</a>
                        </Text>
                    </Section>
                    <Section spacing="xs">
                        <Title size="m">
                            {t(
                                'legal.infomation.commercial.registered.number.label'
                            )}
                        </Title>
                        <Divider spacing="xxs" invisible />

                        <Text>CH-020.3.049.298-4</Text>

                        <Divider spacing="s" invisible />
                        <Title size="m">
                            {t(
                                'legal.infomation.company.registered.number.label'
                            )}
                        </Title>
                        <Divider spacing="xxs" invisible />

                        <Text>CHE-223.965.820</Text>
                        <Text>
                            {t('legal.infomation.company.registered.office')}
                        </Text>
                    </Section>

                    <Section spacing="xs">
                        <Title size="l">
                            {t('legal.infomation.responsible.person')}
                        </Title>
                        <Divider spacing="s" invisible />

                        <Text>
                            <a href="mailto:ne@aepsy.com">Nicolas Egger</a>,{' '}
                            {t('legal.infomation.responsible.person.title')}
                            <br />
                        </Text>
                    </Section>
                    <Section spacing="xs">
                        <Title size="l">
                            {t('legal.infomation.disclaimer.title')}
                        </Title>
                        <Divider spacing="s" invisible />

                        <Text>
                            <Trans
                                i18nKey={'legal.infomation.disclaimer.text'}
                                components={{ br: <br /> }}
                            />
                        </Text>
                    </Section>

                    <Section spacing="xs">
                        <Title size="l">
                            {t('legal.infomation.disclaimer.right.title')}
                        </Title>
                        <Divider spacing="s" invisible />

                        <Text>
                            {t('legal.infomation.disclaimer.right.text')}
                        </Text>
                    </Section>
                    <Section spacing="xs">
                        <Title size="l">
                            {t('legal.infomation.copyrights.title')}
                        </Title>
                        <Divider spacing="s" invisible />

                        <Text>{t('legal.infomation.copyrights.text')}</Text>
                        <Text>
                            {t('legal.infomation.source')}{' '}
                            <a
                                href="https://www.swissanwalt.ch/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                SwissAnwalt
                            </a>
                        </Text>
                    </Section>
                </Section>

                <Footer />
            </div>
        </Layout>
    );
};

export default withTracking(withLocale(ImprintPage));
